

.itemContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.itemLabel{
  margin-top: 5px;
  text-align: center;
  font-weight: 500;
  font-size: 1.5em;
}

.circle{  
  width: 128px; 
  height: 128px; 
  border-radius: 64px;    
  display: flex;
  align-items: center;
  justify-content: center;
}